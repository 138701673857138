import * as React from "react";

import { DestinyCharacter } from './Types'

interface CharacterCardProps {
	character: DestinyCharacter
}

export class CharacterCard extends React.Component<CharacterCardProps> {
	render() {
		let styles = {
			backgroundImage: "url(" + this.props.character.backgroundImageUrl + ")",
		}

		return (
			<div key={this.props.character.characterId} className="character-card" style={styles}>
				<div className="character-class">{this.props.character.className}</div>
				<div className="light">{this.props.character.light}</div>
				<div className="level">Level {this.props.character.level}</div>
			</div>
		)
	}
}
