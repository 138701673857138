import {
  getProfile,
  searchDestinyPlayer,
  getActivityHistory,

  HttpClientConfig,
  ServerResponse,
  DestinyCharacterComponent,
  DestinyHistoricalStatsPeriodGroup
} from 'bungie-api-ts/destiny2';

import { UserInfoCard } from 'bungie-api-ts/user/interfaces'

import { DestinyAccount, DestinyCharacter, DestinyProfile, DestinyActivityInstance } from './Types'

enum BungieMembershipType {
  All = -1
}

enum DestinyComponentType {
  Profiles = 100,
  Characters = 200,

  Collectibles = 800,
}

enum DestinyCollectibleState {
  NotAquired = 1,
}

enum DestinyActivityModeType {
  Story = 2,
}

const BungieApiKey = '22cd93fad4bc4bad842ab25f18218b8d';
const ThroneActivityId = 2032534090;
const PallasGalliotCollectibleHash = 3163873689;

// FathisMunk#2334
// const name = 'Ciarán#1339';
// const name = 'BalancedSeeker6';

enum DestinyClass {
  Titan = 0,
  Hunter = 1,
  Warlock = 2,
  Unknown = 3
}

function characterClassName (classType: DestinyClass) : string {
  switch (classType) {
    case DestinyClass.Hunter:  return 'Hunter';
    case DestinyClass.Titan:  return 'Titan';
    case DestinyClass.Warlock:  return 'Warlock';
    default:           return 'Unknown';
  }
}

function characterComponentToInternal (character: DestinyCharacterComponent) : DestinyCharacter {
  return {
    characterId: character.characterId,
    membershipId: character.membershipId,
    membershipType: character.membershipType,
    level: character.baseCharacterLevel,
    light: character.light,
    className: characterClassName(character.classType as any),
    backgroundImageUrl: "//bungie.net/" + character.emblemBackgroundPath,
  }
}

function userInfoCardToInternal (user: UserInfoCard) : DestinyAccount {
  return {
    displayName: user.displayName,
    membershipId: user.membershipId,
    membershipType: user.membershipType,
    platformIconUrl: user.iconPath ? "//bungie.net/" + user.iconPath : undefined,
  }
}

async function $http(config: HttpClientConfig) {
  let url = new URL(config.url);

  if (config.params) {
    Object.keys(config.params).forEach(key => {
      if (config.params[key])
        url.searchParams.append(key, config.params[key])
    })
  }

  return fetch(url as any, {
    mode: 'cors',
    method: config.method,
    headers: {
      'X-API-Key': BungieApiKey,
    }
  }).then(resp => {
    if (resp.ok) {
      return resp.json();
    }
    throw "Request failed"
  })
}

export async function userSearchProvider(query: string) : Promise<DestinyAccount[]> {
  return searchDestinyPlayer($http, {
    displayName: encodeURIComponent(query),
    membershipType: BungieMembershipType.All as any,
  }).then(resp => resp.Response.map(userInfoCardToInternal));
}

export async function userCharactersProvider (membershipType: number, membershipId: string) : Promise<DestinyProfile> {
  return getProfile($http, {
    components: [
      DestinyComponentType.Profiles as any,
      DestinyComponentType.Characters as any,
      DestinyComponentType.Collectibles as any
    ],
    destinyMembershipId: membershipId,
    membershipType: membershipType as number,
  }).then(resp => {
    let isAcquired = Object.values(resp.Response.characters.data).some(char => {
      let state = resp.Response.characterCollectibles.data[char.characterId].collectibles[PallasGalliotCollectibleHash].state
      return ((state & DestinyCollectibleState.NotAquired) === 0)
    })

    let characters: DestinyCharacter[] = Object.values(resp.Response.characters.data).map(characterComponentToInternal)

    return {
      account: userInfoCardToInternal(resp.Response.profile.data.userInfo),
      dcShipAcquired: isAcquired,
      characters: characters
    }
  });
}

function activityToInternal (activity: DestinyHistoricalStatsPeriodGroup) : DestinyActivityInstance {
  return {
    instanceId: activity.activityDetails.instanceId,
    isCompleted: (activity.values['completed'].basic.value == 1),
    period: new Date(activity.period),
  }
}

export async function characterActivitiesProvider (character: DestinyCharacter) : Promise<DestinyActivityInstance[]> {
  return getActivityHistory($http, {
    characterId: character.characterId,
    membershipType: character.membershipType,
    destinyMembershipId: character.membershipId,
    mode: DestinyActivityModeType.Story as any,
    count: 250,
  }).then(resp => {
    if (!resp.Response.activities)
      return []
    return resp.Response
      .activities
      .filter(activity => (activity.activityDetails.directorActivityHash == ThroneActivityId))
      .map(activityToInternal)
  });
}
