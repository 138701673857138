import * as React from "react";
import ReactTimeAgo from 'react-time-ago'

import JavascriptTimeAgo from 'javascript-time-ago'
JavascriptTimeAgo.locale(require('javascript-time-ago/locale/en'))

import {DestinyActivityInstance} from './Types'

function getDateWeekNumber(input: Date) : number {
	var date = new Date(input.getTime());
	date.setHours(0, 0, 0, 0);
	// Thursday in current week decides the year.
	date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
	// January 4 is always in week 1.
	var week1 = new Date(date.getFullYear(), 0, 4);
	// Adjust to Thursday in week 1 and count number of weeks from date to week1.
	return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000 - 3 + (week1.getDay() + 6) % 7) / 7);
}

interface CharacterActivityListProps {
	activities: DestinyActivityInstance[]
}

export class CharacterActivityList extends React.Component<CharacterActivityListProps, {}> {
	constructor(props: CharacterActivityListProps) {
		super(props)

		this.state = {
		}
	}

	renderActivityEntry(activity: DestinyActivityInstance) {
		let className = (activity.isCompleted && "completed") || ""
		let activityDate = new Date(activity.period);

		return <li key={activity.instanceId} className={className}>
			<ReactTimeAgo date={activityDate} timeStyle={{
				graduation: "convenient",
				flavour: "long",
				units: ["week", "day", "hour", "minute"],
			}} />
		</li>
	}

	render() {
		let activityGroups: Map<number, DestinyActivityInstance[]> =
			this.props.activities
			.slice(0, 5)
			.reduce((result: any, activity: DestinyActivityInstance) => {
				let activityDate = new Date(activity.period);
				let group = getDateWeekNumber(activityDate);

				if (!result.has(group))
					result.set(group, [])

				result.get(group).push(activity);

				return result;
			}, new Map());

		return (
			<div className="activities-container">
				{Array.from(activityGroups.entries()).map(([weekNumber, activities]) =>
					<ul className="activities" key={weekNumber}>
						{activities.map(activity => this.renderActivityEntry(activity))}
					</ul>
				)}
			</div>
		)
	}
}
