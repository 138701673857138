import * as React from "react";
import { Route, Switch, Redirect, Link } from 'react-router-dom'

import {DestinyAccount} from './Types'

export interface UserResult {
  id: string
  imageUrl?: string
  displayName: string
  profileLink: string
  context: any
}

interface UserProfileSearchProps {
  searchProvider: ((query: string) => Promise<UserResult[]>),
  onUserSelected: ((user: UserResult) => void),
}

interface UserProfileSearchState {
  query?: string,
  userResults: UserResult[],
  loading: boolean,
}

export class UserProfileSearch extends React.Component<UserProfileSearchProps, UserProfileSearchState> {
  constructor(props: UserProfileSearchProps) {
    super(props)
    this.commitSearch = this.commitSearch.bind(this)

    this.state = {
      query: undefined,
      userResults: [],
      loading: false,
    }
  }

  performSearch(query: string) {
    if (!query)
      return;

    this.setState({ query: query, loading: true }, () => {
      this.props.searchProvider(query).then(results => {
        console.log("Results:", results)

        this.setState({
          loading: false,
          userResults: results
        })

        if (results.length == 1)
          this.props.onUserSelected(results[0])
      });
    })
  }

  commitSearch(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key !== 'Enter')
      return;

    let query = (event.target as HTMLInputElement).value;
    this.performSearch(query)
  }

  renderUserCard (user: UserResult) {
    let image = null;
    if (user.imageUrl)
        image = <img src={user.imageUrl} />

    return (
      <li key={user.id} onClick={() => this.props.onUserSelected(user)}>
        <Link to={user.profileLink}>
          {image}
          {user.displayName}
         </Link>
      </li>
    )
  }

  render() {
    let message = "";

    if (this.state.loading) {
      message = "Loading…"
    } else if (this.state.query && this.state.userResults.length == 0) {
      message = "No results"
    } else if (this.state.userResults.length > 0) {
      message = "Results:"
    } else {
      message = "Enter an account name to check"
    }

    if (this.state.userResults.length == 1) {
      let user = this.state.userResults[0];
      return <Redirect to={user.profileLink} />
    }

    return (
      <div id="profile-search">
        <input type="text" autoFocus onKeyDown={this.commitSearch} />

        <p className="message">{message}</p>

        {this.state.userResults &&
          <ul>
            {this.state.userResults.map(this.renderUserCard.bind(this))}
          </ul>
        }
      </div>
     )
  }
}
