import * as React from "react";
import { Route, Switch, Redirect, Link } from 'react-router-dom'

import {UserProfileSearch, UserResult} from './UserProfileSearch'
import {UserProfile} from './UserProfile'
import { userSearchProvider, userCharactersProvider, characterActivitiesProvider } from './Services'
import {DestinyAccount} from './Types'

import './App.css';


interface AppState {
  user?: DestinyAccount
}

class App extends React.Component<{}, AppState> {
  constructor(props: {}) {
    super(props)

    this.onUserSelected = this.onUserSelected.bind(this)

    this.state = {
      user: undefined,
    }
  }

  onUserSelected (user: UserResult) {
    this.setState({
      user: user.context as DestinyAccount
    })
  }

  // Convert UserInfoCard results to UserResult for profile search component
  async userResultSearchProvider(query: string) : Promise<UserResult[]> {
    return userSearchProvider(query).then(results =>
      results.map(user =>
        ({
          displayName: user.displayName,
          id: user.membershipId,
          imageUrl: user.platformIconUrl,
          profileLink: `/profile/${user.membershipType}/${user.membershipId}`,
          context: user,
        })
      )
    )
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path='/' render={(props) =>
            <UserProfileSearch
              searchProvider={this.userResultSearchProvider}
              onUserSelected={this.onUserSelected}
            />
          } />

          <Route path='/profile/:membershipType/:membershipId' render={({match}) =>
            <UserProfile
              membershipType={match.params.membershipType}
              membershipId={match.params.membershipId}
              userCharactersProvider={userCharactersProvider}
              characterActivitiesProvider={characterActivitiesProvider}
            />
          } />

          <Redirect to="/" />
        </Switch>
      </div>
    );
  }
}

export default App;
