import * as React from "react";

import { CharacterCard } from './CharacterCard'
import { CharacterActivityList } from './CharacterActivityList'
import { DestinyAccount, DestinyProfile, DestinyCharacter, DestinyActivityInstance } from './Types'

interface UserProfileProps {
	membershipType: number
	membershipId: string
	userCharactersProvider: ((membershipType: number, membershipId: string) => Promise<DestinyProfile>)
	characterActivitiesProvider: ((character: DestinyCharacter) => Promise<DestinyActivityInstance[]>)
}

interface UserProfileState {
	user?: DestinyAccount
	dcShipAcquired: boolean
	characters?: DestinyCharacter[]
	activities: {[key: string]: DestinyActivityInstance[]}
}

export class UserProfile extends React.Component<UserProfileProps, UserProfileState> {
	constructor(props: UserProfileProps) {
		super(props)
		this.state = {
			user: undefined,
			dcShipAcquired: false,
			characters: undefined,
			activities: {},
		}
	}

	componentDidMount() {
		this.props.userCharactersProvider(this.props.membershipType, this.props.membershipId).then(result => {
			this.setState({
				user: result.account,
				dcShipAcquired: result.dcShipAcquired,
				characters: result.characters
			})

			result.characters.forEach(character => {
				this.props.characterActivitiesProvider(character).then(activities => {
					this.setState((state, props) => {
						let allActivities = state.activities;
						allActivities[character.characterId] = activities
						return {activities: allActivities}
					});
				})
			})
		})
	}

	renderCharacterActivities(character: DestinyCharacter) {
		let activities = this.state.activities[character.characterId]

		if (!activities)
			return <p>Loading activities…</p>

		return <CharacterActivityList activities={activities} />
	}

	renderCharacterList() {
		if (!this.state.characters)
			return <p>Loading characters…</p>

		return (
			<div>
				{this.state.dcShipAcquired && <p className="hate-you">You already own the ship, you’re free!</p>}

				{this.state.characters.map(character =>
					<div className="character-results" key={character.characterId}>
						<CharacterCard character={character} />

						{this.renderCharacterActivities(character)}
					</div>
				)}
			</div>
		)
	}

	render() {
		return (
			<div>
				<h2>{this.state.user && this.state.user.displayName}</h2>
				{this.renderCharacterList()}
			</div>
		)
	}
}
